<template>
  <div class="page">
    <Navbar title="用户绑定" type="SMP" />
    <van-form @submit="bind" class="form">
      <van-row class="title">
        <van-col span="24">手机号码绑定</van-col>
      </van-row>
      <van-cell-group>
        <van-field
          v-model="state.phone"
          label="手机号"
          type="tel"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        >
          <template #button>
            <van-button
              size="mini"
              v-if="sendBtnTime > 0"
              :disabled="true"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码({{ sendBtnTime }})</van-button
            >
            <van-button
              size="mini"
              v-if="sendBtnTime <= 0"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码</van-button
            >
          </template></van-field
        >
        <van-field
          v-model="state.vcode"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
        </van-field>
        <van-row>
          <van-col offset="8" span="8" class="subbtn">
            <van-button
              block
              type="primary"
              :color="COLOR"
              size="small"
              native-type="submit"
            >
              立即绑定
            </van-button></van-col
          >
        </van-row>
      </van-cell-group>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <Share ref="share" />
  </div>
</template>
<script>
import { reactive } from 'vue'
import { Form, Field, CellGroup } from 'vant'
import Navbar from '../common/Navbar.vue'
import Share from '../common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup
  },
  setup () {
    const state = reactive({
      phone: '',
      vcode: ''
    })
    return {
      state
    }
  },
  data () {
    return {
      operatorCode: '',
      sendBtnTime: 0,
      loadingShow: false,
      serialCode: '1',
      validateState: false,
      upper: '1'
    }
  },
  mounted () {
    this.operatorCode = window.sessionStorage.getItem(this.SESSION_OPERATOR)
    this.sellerCode = window.sessionStorage.getItem(this.SESSION_SELLER)
    this.refereeCode = window.sessionStorage.getItem(this.SESSION_REFEREE)
    var uc = window.sessionStorage.getItem(this.SESSION_REFEREE)
    var sc = window.sessionStorage.getItem(this.SESSION_SERIAL)
    if (uc !== null && uc !== undefined && uc !== '') {
      this.upper = uc
    }
    if (sc !== null && sc !== undefined && sc !== '') {
      this.serialCode = sc
    }
    this.$refs.share.default('main')
  },
  methods: {
    async bind () {
      if (this.validateState) {
        this.loadingShow = true
        var platform = window.sessionStorage.getItem(this.SESSION_PLATFORM)
        var app = window.sessionStorage.getItem(this.SESSION_APP)
        var pd = { operatorCode: this.operatorCode, sellerCode: this.sellerCode, refereeCode: this.refereeCode, nickname: this.state.phone, phone: this.state.phone, platform: platform, app: app, serialCode: this.serialCode, upper: this.upper, validate: this.state.vcode }
        const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/registerBind', this.$qs.stringify(pd))
        this.loadingShow = false
        if (res.status === '200') {
          window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
          window.sessionStorage.setItem(this.SESSION_USER, res.data.userCode)
          this.createClient()
          this.retrieveWPPUserInfo()
          this.$dialog.alert({
            title: '用户绑定',
            message: '绑定成功'
          }).then(() => {
            this.$router.go(-1)
          })
        } else {
          this.$dialog.alert({
            title: '用户绑定',
            message: res.msg
          }).then(() => {
            // on close
          })
        }
      } else {
        this.$dialog.alert({
          title: '验证码提示',
          message: '请点击发送手机验证码'
        }).then(() => {
          // on close
        })
      }
    },
    async createClient () {
      var pd = {
        name: '',
        phone: this.state.phone,
        personCode: '',
        business: 'RCT',
        type: 'PTL',
        operator: this.operatorCode,
        seller: this.sellerCode,
        referee: this.refereeCode
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/call/client/createClient', this.$qs.stringify(pd))
      if (res.status === '200') {
      }
    },
    async sendValidate () {
      var pd = { phone: this.state.phone }
      var that = this
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/sendValidate', this.$qs.stringify(pd))
      if (res.status === '200') {
        that.validateState = true
        that.sendBtnTime = 60
        that.countdown()
      }
    },
    async retrieveWPPUserInfo () {
      var openid = window.sessionStorage.getItem(this.SESSION_SERIAL)
      var app = window.sessionStorage.getItem(this.SESSION_APP)
      var pd = { app: app, openid: openid }
      var { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveWPPUserInfo', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_SUBSCRIBE, res.data.subscribe)
      } else {
        window.sessionStorage.setItem(this.SESSION_SUBSCRIBE, '0')
      }
    },
    countdown () {
      var that = this
      setTimeout(function () {
        that.sendBtnTime--
        if (that.sendBtnTime > 0) {
          that.countdown()
        }
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 50px 5px;
}
.title {
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
